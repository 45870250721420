// src/pages/ResourceDetailPage.jsx

import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronLeft, Clock } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import resources from '../data/Resources.js'; // Ensure correct path

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, delay: custom * 0.2 },
  }),
};

const ResourceDetailPage = () => {
  const { id } = useParams();

  const resource = useMemo(
    () => resources.find((res) => res.id === parseInt(id, 10)),
    [id]
  );

  if (!resource) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <div className="text-center px-4">
          <h2 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">
            Resource Not Found
          </h2>
          <Link
            to="/resources"
            className="text-blue-600 hover:text-blue-800 font-semibold inline-flex items-center"
            aria-label="Back to Resources"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Resources
          </Link>
        </div>
      </div>
    );
  }

  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 flex flex-col">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>{resource.title} - Ascend Consulting</title>
        <meta name="description" content={resource.description} />
        <meta property="og:title" content={`${resource.title} - Ascend Consulting`} />
        <meta property="og:description" content={resource.description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={pageUrl} />
      </Helmet>

      <main className="flex-grow py-20 bg-gray-100 dark:bg-gray-900" aria-labelledby="resource-detail-content">
        <div className="container mx-auto px-6 max-w-3xl">
          {/* Breadcrumb Navigation */}
          <nav className="text-sm mb-4" aria-label="Breadcrumb">
            <ol className="list-none p-0 inline-flex items-center">
              <li>
                <Link to="/" className="text-blue-600 hover:text-blue-800">
                  Home
                </Link>
                <ChevronLeft size={16} className="mx-2 text-gray-500 inline-block" />
              </li>
              <li aria-current="page">
                <Link to="/resources" className="text-blue-600 hover:text-blue-800">
                  Resources
                </Link>
              </li>
            </ol>
          </nav>

          {/* Category and Read Time */}
          <motion.div
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
            custom={1}
            className="flex items-center space-x-4 mb-4"
          >
            <span
              className="inline-block bg-blue-100 text-blue-800 text-xs md:text-sm px-2 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300"
              aria-label={`Category: ${resource.category}`}
            >
              {resource.category}
            </span>
            <span
              className="text-gray-500 text-sm md:text-base flex items-center dark:text-gray-400"
              aria-label={`Estimated read time: ${resource.readTime}`}
            >
              <Clock size={16} className="inline mr-1" aria-hidden="true" />
              {resource.readTime}
            </span>
          </motion.div>

          {/* Resource Content */}
          <motion.div
            id="resource-detail-content"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
            custom={2}
            className="prose prose-lg text-gray-700 dark:text-gray-300"
          >
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {resource.content}
            </ReactMarkdown>
          </motion.div>

          {/* Back to Resources Link */}
          <motion.div
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
            custom={3}
            className="mt-8"
          >
            <Link
              to="/resources"
              className="text-blue-600 hover:text-blue-800 font-semibold inline-flex items-center"
              aria-label="Back to Resources"
            >
              <ChevronLeft size={16} className="mr-1" />
              Back to Resources
            </Link>
          </motion.div>
        </div>
      </main>
    </div>
  );
};

export default React.memo(ResourceDetailPage);
