// src/App.jsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Import Pages
import FrontPage from './pages/FrontPage';
import AboutUsPage from './pages/AboutUsPage';
import ApproachPage from './pages/ApproachPage';
import ContactPage from './pages/ContactPage';
import ResourcesPage from './pages/ResourcesPage';
import ResourceDetailPage from './pages/ResourceDetailPage';
import ServicesPage from './pages/ServicesPage';
import FAQPage from './pages/FAQPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import NotFoundPage from './pages/NotFoundPage';

// Import Components
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import ScheduleCall from './components/ScheduleCall'; 

const App = () => {
  return (
    <HelmetProvider>
      {/* Scroll Restoration */}
      <ScrollToTop />

      {/* Header */}
      <Header />

      {/* Main Content Wrapped with Error Boundary */}
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/approach" element={<ApproachPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/schedule-call" element={<ScheduleCall />} />
          <Route path="/resources" element={<ResourcesPage />} />
          <Route path="/resources/:id" element={<ResourceDetailPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ErrorBoundary>

      {/* Footer */}
      <Footer />
    </HelmetProvider>
  );
};

export default App;