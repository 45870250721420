// src/pages/AboutUsPage.jsx

import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import {
  ChevronRight,
  Lightbulb,
  UserCheck,
  Globe,
  Users,
  Zap,
  Rocket,
} from 'lucide-react';

import CoreValue from '../components/CoreValue';
import SchoolsSection from '../components/SchoolsSection';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, delay: custom * 0.2 },
  }),
};

const CORE_VALUES = [
  {
    title: 'Excellence',
    description: 'Striving for the highest standards in everything we do.',
    icon: Lightbulb,
  },
  {
    title: 'Integrity',
    description: 'Upholding honesty and transparency at all times.',
    icon: UserCheck,
  },
  {
    title: 'Cultural Bridge',
    description: 'Connecting Chinese and American educational landscapes.',
    icon: Globe,
  },
  {
    title: 'Personalization',
    description: 'Tailoring our approach to each student’s unique needs.',
    icon: Users,
  },
  {
    title: 'Innovation',
    description: 'Embracing new methods and technologies for better results.',
    icon: Zap,
  },
  {
    title: 'Empowerment',
    description: 'Equipping students with skills for long-term success.',
    icon: Rocket,
  },
];

const AboutUsPage = () => {
  const coreValues = useMemo(() => CORE_VALUES, []);

  return (
    <div className="min-h-screen bg-white text-gray-900 font-sans flex flex-col">
      <Helmet>
        <title>About Us - Ascend Consulting</title>
        <meta
          name="description"
          content="Learn more about Ascend Consulting's mission, values, and the professional team dedicated to empowering students to achieve their academic dreams."
        />
      </Helmet>

      <main className="flex-grow">
        {/* HERO SECTION */}
        <section
          className="relative pt-32 pb-12 flex items-center justify-center text-center bg-gradient-to-tr from-gray-800 to-gray-900 overflow-hidden"
          aria-labelledby="aboutus-hero-heading"
        >
          <div className="absolute inset-0 bg-black opacity-30" aria-hidden="true"></div>
          <div className="container mx-auto px-6 relative z-10">
            <motion.h1
              id="aboutus-hero-heading"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              custom={1}
              className="text-5xl font-bold mb-10 text-white"
            >
              About Us
            </motion.h1>
          </div>
        </section>

        {/* SCHOOLS SHOWCASE SECTION */}
        <SchoolsSection />

        {/* CORE VALUES SECTION */}
        <section
          className="py-20 bg-gray-100"
          aria-labelledby="corevalues-section-heading"
        >
          <div className="container mx-auto px-6">
            <motion.h2
              id="corevalues-section-heading"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={1}
              className="text-3xl font-bold mb-12 text-center text-gray-900"
            >
              Our Core Values
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {coreValues.map((value, index) => (
                <CoreValue key={value.title} value={value} index={index} />
              ))}
            </div>
          </div>
        </section>

        {/* CALL TO ACTION SECTION */}
        <section
          className="py-20 bg-white"
          aria-labelledby="aboutus-cta-heading"
        >
          <div className="container mx-auto px-6 text-center">
            <motion.h2
              id="aboutus-cta-heading"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={1}
              className="text-3xl font-bold mb-8 text-gray-900"
            >
              Ready to Elevate Your Academic Future?
            </motion.h2>
            <motion.p
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={2}
              className="text-xl mb-12 max-w-2xl mx-auto text-gray-700"
            >
              Contact us today to schedule your personalized consultation.
            </motion.p>
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={3}
              whileHover={{ scale: 1.05 }}
              className="inline-flex items-center"
            >
              <Link
                to="/contact"
                className="bg-gray-900 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-all hover:shadow-lg flex items-center group"
                aria-label="Contact Us"
              >
                Contact Us
                <ChevronRight
                  size={20}
                  className="ml-2 transition-transform group-hover:translate-x-1"
                  aria-hidden="true"
                />
              </Link>
            </motion.div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default React.memo(AboutUsPage);
