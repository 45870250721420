// src/components/ContactInfo.jsx

import React from 'react';
import { Mail, Phone, MapPin, CalendarCheck, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

const ContactInfo = () => {
  return (
    <motion.div
      className="bg-white rounded-lg shadow-lg p-8 relative w-full"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
    >
      {/* Scarcity Banner */}
      <div className="absolute top-0 left-0 right-0 bg-red-500 text-white text-center py-2 rounded-t-lg">
        Only 9 Free Assessments Left This Month!
      </div>

      {/* Spacer for the banner */}
      <div className="h-8"></div>

      <h3 className="text-2xl font-bold mb-6">Contact Information</h3>
      <p className="text-gray-700 mb-6">
        Schedule your free college admissions assessment today. Our expert counselors are ready to help you navigate the path to your dream university.
      </p>
      <address className="not-italic space-y-4">
        {/* Email */}
        <div className="flex items-center">
          <Mail
            className="text-gray-900 mr-4"
            size={24}
            aria-hidden="true"
            aria-label="Email"
          />
          <a
            href="mailto:info@ascendconsulting.io"
            className="text-gray-700 hover:text-gray-900 transition-colors"
          >
            info@ascendconsulting.io
          </a>
        </div>
        {/* Phone */}
        <div className="flex items-center">
          <Phone
            className="text-gray-900 mr-4"
            size={24}
            aria-hidden="true"
            aria-label="Phone"
          />
          <a
            href="tel:+15551234567"
            className="text-gray-700 hover:text-gray-900 transition-colors"
          >
            +1 (347) 688-3001    
          </a>
        </div>
        {/* Address */}
        <div className="flex items-center">
          <MapPin
            className="text-gray-900 mr-4"
            size={24}
            aria-hidden="true"
            aria-label="Address"
          />
          <span className="text-gray-700">
            606 West 57th, New York, NY 10019
          </span>
        </div>
      </address>

      {/* Google Maps Embed */}
      <motion.div
        className="mt-6 rounded-lg overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <iframe
          title="Ascend Consulting Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.910123456789!2d-74.0060!3d40.7128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a3167c4b7b1%3A0x1a2b3c4d5e6f7g8h!2s123%20Education%20Lane%2C%20New%20York%2C%20NY%2010001!5e0!3m2!1sen!2sus!4v1615555555555!5m2!1sen!2sus"
          width="100%"
          height="200"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="rounded-lg"
        ></iframe>
        <a
          href="https://www.google.com/maps/place/606+W+57th+St,+New+York,+NY+10019/@40.7706208,-73.9925102,16z/data=!3m1!4b1!4m6!3m5!1s0x89c2585b77850933:0x9879d50fa0be2e3c!8m2!3d40.7706208!4d-73.9925102!16s%2Fg%2F11f15jm0tb?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-center mt-2 text-blue-600 hover:text-blue-800 transition-colors text-sm"
        >
          View on Google Maps
        </a>
      </motion.div>

      {/* Call to Action Button */}
      <motion.div
        className="mt-8"
        initial={{ opacity: 0, scale: 0.9 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <Link
          to="/schedule-call" // Ensure this route exists
          className="w-full flex items-center justify-center bg-indigo-600 text-white px-4 py-3 rounded-md font-semibold hover:bg-indigo-700 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500"
          aria-label="Schedule a Free Consultation"
        >
          <CalendarCheck className="w-5 h-5 mr-2" />
          Schedule a Free Consultation
          <ArrowRight className="w-5 h-5 ml-2" />
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default ContactInfo;
