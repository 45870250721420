// src/pages/ApproachPage.jsx

import React, { useMemo } from 'react';
import { ChevronRight, Target, Users, Sparkles, Briefcase, ChartBar, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ApproachStep from '../components/ApproachStep';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, delay: custom * 0.2 },
  }),
};

const APPROACH_STEPS = [
  {
    title: 'Personalized Assessment',
    description:
      'Identify strengths, clarify goals, and pinpoint areas for improvement.',
    icon: Target,
    summaryPoints: [
      'Identify key strengths',
      'Pinpoint areas for improvement',
      'Clarify academic and career goals',
    ],
  },
  {
    title: 'Tailored Strategy Development',
    description:
      'Craft a customized roadmap aligned with your goals.',
    icon: ChartBar,
    summaryPoints: [
      'Customized application strategy',
      'School selection guidance',
      'Timeline and milestone planning',
    ],
  },
  {
    title: 'Holistic Profile Enhancement',
    description:
      'Develop a well-rounded profile that stands out.',
    icon: Users,
    summaryPoints: [
      'Academic performance optimization',
      'Extracurricular involvement',
      'Leadership skill development',
    ],
  },
  {
    title: 'Application Mastery',
    description:
      'Master every step of the application process.',
    icon: Sparkles,
    summaryPoints: [
      'Standout application materials',
      'Interview preparation',
      'Recommendation letter strategy',
    ],
  },
  {
    title: 'Ongoing Support and Mentorship',
    description:
      'Receive continuous guidance and refined strategies.',
    icon: Clock,
    summaryPoints: [
      'Regular progress check-ins',
      'Adaptive strategy refinement',
      'Well-being support',
    ],
  },
  {
    title: 'Future-Focused Preparation',
    description:
      'Gain skills and perspectives for long-term success.',
    icon: Briefcase,
    summaryPoints: [
      'Career exploration',
      'Networking skill development',
      'Long-term success planning',
    ],
  },
];

const ApproachPage = () => {
  const approachSteps = useMemo(() => APPROACH_STEPS, []);

  return (
    <div className="min-h-screen bg-white text-gray-900 font-sans flex flex-col">
      <Helmet>
        <title>Our Approach - Ascend Consulting</title>
        <meta
          name="description"
          content="Discover Ascend Consulting's holistic and personalized approach to college admissions, designed to maximize your chances of success."
        />
      </Helmet>

      <main className="flex-grow">
        {/* HERO SECTION */}
        <section
          className="relative pt-32 pb-12 flex items-center justify-center text-center bg-gradient-to-tr from-gray-800 to-gray-900 overflow-hidden"
          aria-labelledby="approach-hero-heading"
        >
          <div className="absolute inset-0 bg-black opacity-30" aria-hidden="true"></div>
          <div className="container mx-auto px-6 relative z-10">
            <motion.h1
              id="approach-hero-heading"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              custom={1}
              className="text-5xl font-bold mb-10 text-white"
            >
              Our Approach
            </motion.h1>
          </div>
        </section>

        {/* APPROACH STEPS SECTION */}
        <section
          id="approach-steps"
          className="py-20 bg-white"
          aria-labelledby="approach-steps-heading"
        >
          <div className="container mx-auto px-6">
            <motion.h2
              id="approach-steps-heading"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={1}
              className="text-3xl font-bold mb-12 text-center text-gray-900"
            >
              Our Methodology
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {approachSteps.map((step, index) => (
                <ApproachStep key={step.title} step={step} index={index} />
              ))}
            </div>
          </div>
        </section>

        {/* CALL TO ACTION SECTION */}
        <section
          className="py-20 bg-gray-100"
          aria-labelledby="approach-cta-heading"
        >
          <div className="container mx-auto px-6 text-center">
            <motion.h3
              id="approach-cta-heading"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={1}
              className="text-3xl font-bold mb-8 text-gray-900"
            >
              Ready to Experience Our Approach?
            </motion.h3>
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={2}
              whileHover={{ scale: 1.05 }}
              className="inline-flex items-center"
            >
              <Link
                to="/contact"
                className="bg-gray-900 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-all hover:shadow-lg inline-flex items-center group"
                aria-label="Schedule a Consultation"
              >
                Schedule a Consultation
                <ChevronRight
                  size={20}
                  className="ml-2 transition-transform group-hover:translate-x-1"
                  aria-hidden="true"
                />
              </Link>
            </motion.div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default React.memo(ApproachPage);
