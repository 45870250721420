// src/pages/ContactPage.jsx

import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

// Import Components
import ScheduleCall from '../components/ScheduleCall';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/ContactForm';
import FAQItem from '../components/FAQItem';

// FAQs data with unique IDs
const faqs = [
  {
    id: 1,
    question: 'How do I schedule a free assessment?',
    answer:
      'You can schedule your free assessment by using our scheduling tool below. One of our counselors will reach out to confirm your appointment.',
  },
  {
    id: 2,
    question: 'What does the free assessment include?',
    answer:
      'Our free assessment includes a review of your academic profile, extracurricular activities, and goals, providing personalized recommendations to enhance your admissions prospects.',
  },
  {
    id: 3,
    question: 'Are there any obligations after the free assessment?',
    answer:
      'No, there are no obligations. The free assessment is our way of providing initial value. If you find our services beneficial, we can discuss tailored plans afterward.',
  },
  {
    id: 4,
    question: 'How many free assessments are available?',
    answer:
      'We offer a limited number of free assessments each month to ensure personalized attention.',
  },
  {
    id: 5,
    question: 'Can international students apply for the free assessment?',
    answer:
      'Yes, we welcome international students. Our counselors are experienced in guiding students through the unique challenges of international admissions.',
  },
];

const ContactPage = () => {
  return (
    <div className="min-h-screen bg-white text-gray-900 font-sans flex flex-col">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Contact Us - Ascend Consulting</title>
        <meta
          name="description"
          content="Schedule your free college admissions assessment with Ascend Consulting and receive personalized guidance from our expert counselors."
        />
        <link rel="canonical" href="https://www.ascendconsulting.com/contact" />
        <meta property="og:title" content="Contact Us - Ascend Consulting" />
        <meta
          property="og:description"
          content="Schedule your free college admissions assessment with Ascend Consulting. Provide your details to get personalized guidance from our expert counselors."
        />
        <meta property="og:url" content="https://www.ascendconsulting.com/contact" />
      </Helmet>

      <main className="flex-grow">
        {/* HERO SECTION */}
        <section
          className="relative pt-32 pb-12 flex items-center justify-center text-center bg-gradient-to-tr from-gray-800 to-gray-900 overflow-hidden"
          aria-labelledby="contact-hero-heading"
        >
          {/* Overlay for better text readability */}
          <div className="absolute inset-0 bg-black opacity-30" aria-hidden="true"></div>
          <div className="container mx-auto px-6 relative z-10">
            <motion.h1
              id="contact-hero-heading"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-5xl font-bold mb-10 text-white"
            >
              Schedule Your Free Assessment
            </motion.h1>
          </div>
        </section>

        {/* CONTACT AND FAQ SECTION */}
        <section
          className="py-20 bg-gray-50"
          aria-labelledby="contact-faq-section-heading"
        >
          <div className="container mx-auto px-6">
            <div className="flex flex-col lg:flex-row lg:space-x-12">
              {/* Left Column: Scheduling and Contact Info */}
              <div className="flex-1 mb-12 lg:mb-0">
                {/* Schedule Call */}
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  className="mb-12"
                >
                  <ScheduleCall />
                </motion.div>

                {/* Contact Information */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <ContactInfo />
                </motion.div>
              </div>

              {/* Right Column: Contact Form and FAQs */}
              <div className="flex-1">
                {/* Contact Form */}
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  className="mb-12"
                >
                  <ContactForm />
                </motion.div>

                {/* FAQs */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.6 }}
                >
                  <h3
                    id="contact-faq-section-heading"
                    className="text-2xl font-bold mb-6 text-gray-900"
                  >
                    Frequently Asked Questions
                  </h3>
                  <div className="space-y-4">
                    {faqs.map((faq) => (
                      <FAQItem
                        key={faq.id}
                        id={faq.id}
                        question={faq.question}
                        answer={faq.answer}
                      />
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default React.memo(ContactPage);
