// src/pages/ServicesPage.jsx

import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import {
  ChevronRight,
  Briefcase,
  BookOpen,
  Users,
  CheckCircle,
  Award,
  TrendingUp,
} from 'lucide-react';

import DetailedServiceCard from '../components/DetailedServiceCard';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, delay: custom * 0.2 },
  }),
};

const SERVICES_DATA = [
  {
    icon: TrendingUp,
    label: 'Application Strategy',
    description: 'Personalized guidance for a focused application roadmap.',
    summaryPoints: [
      'Tailored application planning',
      'School selection guidance',
      'Timeline and milestone setting',
    ],
  },
  {
    icon: Award,
    label: 'Essay Development',
    description: 'Crafting compelling essays that stand out.',
    summaryPoints: [
      'Topic brainstorming',
      'Professional editing',
      'Personal statement refinement',
    ],
  },
  {
    icon: Users,
    label: 'Interview Preparation',
    description: 'Comprehensive interview coaching and practice.',
    summaryPoints: [
      'Mock interview sessions',
      'Constructive feedback',
      'Confidence-building techniques',
    ],
  },
  {
    icon: BookOpen,
    label: 'Test Preparation',
    description: 'SAT/ACT prep for higher scores.',
    summaryPoints: [
      'Personalized tutoring',
      'Practice exams',
      'Test-taking strategies',
    ],
  },
  {
    icon: Briefcase,
    label: 'Career Coaching',
    description: 'Guidance to explore career paths and build résumés.',
    summaryPoints: [
      'Career exploration',
      'Résumé building',
      'Networking strategies',
    ],
  },
  {
    icon: CheckCircle,
    label: 'Personalized Attention',
    description: 'Dedicated one-on-one support to reach your goals.',
    summaryPoints: [
      'Individualized sessions',
      'Goal setting',
      'Continuous support',
    ],
  },
];

const ServicesPage = () => {
  const services = useMemo(() => SERVICES_DATA, []);

  return (
    <div className="min-h-screen bg-white text-gray-900 font-sans flex flex-col">
      <Helmet>
        <title>Our Services - Ascend Consulting</title>
        <meta
          name="description"
          content="Explore Ascend Consulting's comprehensive services, guiding you towards academic success and top-tier university admissions."
        />
      </Helmet>

      <main className="flex-grow">
        {/* HERO SECTION */}
        <section
          className="relative pt-32 pb-12 flex items-center justify-center text-center bg-gradient-to-tr from-gray-800 to-gray-900 overflow-hidden"
          aria-labelledby="services-hero-heading"
        >
          <div className="absolute inset-0 bg-black opacity-30" aria-hidden="true"></div>
          <div className="container mx-auto px-6 relative z-10">
            <motion.h1
              id="services-hero-heading"
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              custom={1}
              className="text-5xl font-bold mb-10 text-white"
            >
              Our Services
            </motion.h1>
          </div>
        </section>

        {/* SERVICES SECTION */}
        <section
          className="py-20 bg-white"
          aria-labelledby="services-section-heading"
        >
          <div className="container mx-auto px-6">
            <motion.h2
              id="services-section-heading"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={1}
              className="text-3xl font-bold mb-12 text-center text-gray-900"
            >
              What We Offer
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {services.map((service) => (
                <DetailedServiceCard
                  key={service.label}
                  icon={service.icon}
                  label={service.label}
                  description={service.description}
                  summaryPoints={service.summaryPoints}
                />
              ))}
            </div>
          </div>
        </section>

        {/* CALL TO ACTION SECTION */}
        <section
          className="py-20 bg-gray-100"
          aria-labelledby="services-cta-heading"
        >
          <div className="container mx-auto px-6 text-center">
            <motion.h3
              id="services-cta-heading"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={1}
              className="text-3xl font-bold mb-8 text-gray-900"
            >
              Ready to Elevate Your Academic Journey?
            </motion.h3>
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              custom={2}
              whileHover={{ scale: 1.05 }}
            >
              <Link
                to="/contact"
                className="bg-gray-900 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-all hover:shadow-lg inline-flex items-center group"
                aria-label="Get Started"
              >
                Get Started
                <ChevronRight
                  size={20}
                  className="ml-2 transition-transform group-hover:translate-x-1"
                  aria-hidden="true"
                />
              </Link>
            </motion.div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default React.memo(ServicesPage);
