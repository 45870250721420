// src/pages/FrontPage.jsx

import React, { Suspense, lazy, useMemo } from 'react';
import {
  ChevronRight,
  Award,
  TrendingUp,
  Users,
  BookOpen,
  Briefcase,
  CheckCircle,
} from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Lazy load components for better performance
const StatCard = lazy(() => import('../components/StatCard'));
const ServiceCard = lazy(() => import('../components/ServiceCard'));

// Motion Variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.2, duration: 0.8 },
  }),
};

const FrontPage = () => {
  // Memoized data arrays to prevent unnecessary recalculations
  const stats = useMemo(
    () => [
      { icon: Award, label: '3× Higher Ivy League Acceptance' },
      { icon: TrendingUp, label: '95% Success Rate at Top 20 Universities' },
      { icon: Users, label: '$1.7M+ in Scholarships Awarded' },
    ],
    []
  );

  const services = useMemo(
    () => [
      {
        icon: TrendingUp,
        label: 'Application Strategy',
        description:
          'Customized plans to enhance your application and maximize your chances of admission.',
      },
      {
        icon: Award,
        label: 'Essay Writing',
        description:
          'Professional guidance to craft compelling personal statements and application essays.',
      },
      {
        icon: Briefcase,
        label: 'Career Coaching',
        description:
          'Sessions with top professionals to explore career paths and build strong resumes.',
      },
      {
        icon: BookOpen,
        label: 'Test Preparation',
        description:
          'Comprehensive SAT/ACT prep, including tutoring and full-length practice exams.',
      },
      {
        icon: Users,
        label: 'Interview Coaching',
        description:
          'Mock interviews and coaching sessions to help you ace college admissions interviews.',
      },
      {
        icon: CheckCircle,
        label: 'Personalized Attention',
        description:
          'One-on-one sessions addressing your unique academic profile and goals.',
      },
    ],
    []
  );

  return (
    <div className="min-h-screen bg-white text-gray-900 font-sans">
      {/* HERO SECTION */}
      <header>
        <section
          className="relative h-screen flex items-center justify-center bg-gradient-to-tr from-indigo-600 to-purple-700 overflow-hidden"
          aria-labelledby="hero-heading"
        >
          {/* Dark overlay for readability */}
          <div
            className="absolute inset-0 bg-black opacity-40"
            aria-hidden="true"
          ></div>

          <div className="container mx-auto px-6 text-center relative z-10">
            <motion.h1
              id="hero-heading"
              className="text-5xl md:text-7xl font-bold mb-6 leading-tight text-white"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={1}
              viewport={{ once: true }}
            >
              Elevate Your{' '}
              <span className="text-gray-300">Academic Future</span>
            </motion.h1>
            <motion.p
              className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto text-gray-300"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={2}
              viewport={{ once: true }}
            >
              Strategic guidance for admission to elite universities. Our expert
              team provides personalized counseling to maximize your success.
            </motion.p>
            <motion.div
              className="flex flex-col sm:flex-row gap-4 justify-center"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={3}
              viewport={{ once: true }}
            >
              <Link
                to="/contact"
                className="bg-gray-900 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-colors flex items-center justify-center group"
                aria-label="Begin Your Journey"
              >
                Begin Your Journey
                <ChevronRight
                  size={20}
                  className="ml-2 transition-transform group-hover:translate-x-1"
                  aria-hidden="true"
                />
              </Link>
              <Link
                to="/services"
                className="border-2 border-white text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-white hover:text-gray-900 transition-colors flex items-center justify-center"
                aria-label="Explore Our Services"
              >
                Explore Our Services
              </Link>
            </motion.div>
          </div>
        </section>
      </header>

      <main>
        {/* STATS SECTION */}
        <section className="py-20 bg-white" aria-labelledby="stats-heading">
          <div className="container mx-auto px-6">
            <motion.h2
              id="stats-heading"
              className="text-3xl font-bold mb-12 text-center text-gray-900"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={1}
              viewport={{ once: true }}
            >
              Proven Success in Admissions
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <Suspense fallback={<LoadingStatCards />}>
                {stats.map((stat, index) => (
                  <motion.div
                    key={stat.label}
                    variants={fadeInUp}
                    initial="hidden"
                    whileInView="visible"
                    custom={index + 1}
                    viewport={{ once: true }}
                  >
                    <StatCard icon={stat.icon} label={stat.label} />
                  </motion.div>
                ))}
              </Suspense>
            </div>
          </div>
        </section>

        {/* SERVICES SECTION */}
        <section
          className="py-20 bg-gray-100"
          aria-labelledby="services-heading"
        >
          <div className="container mx-auto px-6">
            <motion.h2
              id="services-heading"
              className="text-3xl font-bold mb-12 text-center text-gray-900"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={1}
              viewport={{ once: true }}
            >
              Our Services
            </motion.h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              <Suspense fallback={<LoadingServiceCards />}>
                {services.map((service, index) => (
                  <motion.div
                    key={service.label}
                    variants={fadeInUp}
                    initial="hidden"
                    whileInView="visible"
                    custom={index + 1}
                    viewport={{ once: true }}
                  >
                    <ServiceCard
                      icon={service.icon}
                      label={service.label}
                      description={service.description}
                    />
                  </motion.div>
                ))}
              </Suspense>
            </div>
          </div>
        </section>

        {/* CALL-TO-ACTION SECTION */}
        <section className="py-20 bg-white" aria-labelledby="cta-heading">
          <div className="container mx-auto px-6 text-center">
            <motion.h2
              id="cta-heading"
              className="text-3xl font-bold mb-8 text-gray-900"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={1}
              viewport={{ once: true }}
            >
              Ready to Elevate Your Academic Future?
            </motion.h2>
            <motion.p
              className="text-xl mb-12 max-w-2xl mx-auto text-gray-600"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={2}
              viewport={{ once: true }}
            >
              Contact us today to schedule your personalized consultation and
              take the first step towards your dream university.
            </motion.p>
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              custom={3}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              className="inline-flex items-center"
            >
              <Link
                to="/contact"
                className="bg-gray-900 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-all hover:shadow-lg flex items-center group"
                aria-label="Contact Us"
              >
                Contact Us
                <ChevronRight
                  size={20}
                  className="ml-2 transition-transform group-hover:translate-x-1"
                  aria-hidden="true"
                />
              </Link>
            </motion.div>
          </div>
        </section>
      </main>
    </div>
  );
};

// Loading Fallbacks
const LoadingStatCards = () => (
  <div className="flex justify-center items-center h-32">
    <span
      className="text-gray-500"
      role="status"
      aria-label="Loading statistics..."
    >
      Loading statistics...
    </span>
  </div>
);

const LoadingServiceCards = () => (
  <div className="flex justify-center items-center h-32">
    <span
      className="text-gray-500"
      role="status"
      aria-label="Loading services..."
    >
      Loading services...
    </span>
  </div>
);

export default React.memo(FrontPage);